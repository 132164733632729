import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

const imageWrappedPickles = '/tysonscore3.azureedge.net/assets/media/wrightbrand/images/recipes/wrightbrand-the-bacon-wrappedpickle-recipe-1367x737px.jpg';
const imageUltimateBLT = '/tysonscore3.azureedge.net/assets/media/wrightbrand/images/recipes/wrightbrand-ultimate-blt-recipe-1367x737px.jpg';
const imageBaconBao = '/tysonscore3.azureedge.net/assets/media/wrightbrand/images/recipes/wrightbrand-bacon-bao-recipe-1367x737px.jpg';
const imageBaconBrunch = '/tysonscore3.azureedge.net/assets/media/wrightbrand/images/recipes/wrightbrand-brunch-burger-recipe-1367x737px.jpg';
const imageBaconLoversSan = '/tysonscore3.azureedge.net/assets/media/wrightbrand/images/recipes/wrightbrand-the-adulting-iltimate-bacon-lovers-sandwich-recipe-1367x737px.jpg';

const imageKlondikeSandwich = '/tysonscore3.azureedge.net/assets/media/wrightbrand/images/recipes/Wright-Brand-Recipe-Klondike-Sandwich-Lifestyle-1367×737.jpg';
const imageKlondikeBonbons= '/tysonscore3.azureedge.net/assets/media/wrightbrand/images/recipes/Wright-Brand-Recipe-Klondike-BonBons-Lifestyle-1367×737.jpg';
const imageKlondikeBrittle = '/tysonscore3.azureedge.net/assets/media/wrightbrand/images/recipes/Wright-Brand-Recipe-Klondike-Brittle-Lifestyle-1367×737.jpg';
const imageBaconWaffles = '/tysonscore3.azureedge.net/assets/media/wrightbrand/images/recipes/wbb_baconwaffles_1362x737_V2.jpg';
const imageBaconCornBread = '/tysonscore3.azureedge.net/assets/media/wrightbrand/images/recipes/baconcornbread_small.jpg';
const imageLollipopsSmall= '/tysonscore3.azureedge.net/assets/media/wrightbrand/images/recipes/lollipops_small.jpg';
const imageShrimpCrawFish = '/tysonscore3.azureedge.net/assets/media/wrightbrand/images/recipes/shrimpcrawfishskewer_large.jpg';
const imageLitlSmokies= '/tysonscore3.azureedge.net/assets/media/wrightbrand/images/recipes/litlsmokies_webresized_360x195.jpg';
const imageBaconWrapCorn = '/tysonscore3.azureedge.net/assets/media/wrightbrand/images/recipes/baconwrapcorn_small.jpg';
const imageBrazedChicken = '/tysonscore3.azureedge.net/assets/media/wrightbrand/images/recipes/brazedchicken_large.jpg';
const imageBaconHazelnut = '/tysonscore3.azureedge.net/assets/media/wrightbrand/images/recipes/farrosalad_large.jpg'


const SecondPage = () => (
    <Layout metaTitle="Bacon Recipes" metaDescription="When it comes to our thick-cut naturally smoked bacon we don&#39;t cut corners. We take the same approach with our&#160;bacon recipes &amp; are happy to share!">

        <article class="page-section with-placeholder search">
            <div class="image"><img src={'/tysonscore3.azureedge.net/assets/media/wrightbrand/images/backgrounds/wrightbrand_recipeimage.jpg'} alt="Recipes"></img>
            </div>
        </article>
        <div class="page-title-description">
            <div class="title-description-container container">
                <h1>Bacon Recipes</h1>
                <p>
                    When it comes to our thick-cut naturally smoked bacon we don't cut corners. So, it should come as no surprise we take the same approach with our recipes. After all, an expertly crafted bacon deserves an expertly crafted dish.
        </p>
            </div>
        </div>



        <article class="page-section recipe-category-list">
            <section class="recipe-category-section">
                <div class="container recipe-list-container">
                    <input type="hidden" id="hid-current-page" value="2" />
                    <input type="hidden" id="load-more-count" value="3" />
                    <div class="category-container">


                        <img class="category-image hidden-lg hidden-md hidden-sm" src={imageBaconCornBread} alt="New Recipes" />


                        <div class="category-header">
                            <h2>New Recipes</h2>
                        </div>
                        <div class="generic-btn mobile-category-btn arrow hidden-lg hidden-md hidden-sm" data-id="00000000-0000-0000-0000-000000000000">
                            <a class="more-recipes-btn">
                                View Recipes
                    </a>
                        </div>
                    </div>
                    <div class="recipe-list row">
                        <div class="recipe-item col-sm-6 col-md-4">
                            <a href="/recipes/ultimate-bacon-lovers-sandwich/">
                                <img src={imageBaconLoversSan} alt="ultimate-bacon-lovers-sandwich"></img>
                            </a>
                            <h3>“The Adulting” Ultimate Bacon Lover's Sandwich</h3>
                            <p>
                            It's called 'Adulting' because this sandwich isn't for beginners. <a class="hidden-xs full-recipe" href="/recipes/ultimate-bacon-lovers-sandwich/">Full Recipe</a>
                            </p>
                            <div class="generic-btn arrow hidden-lg hidden-md hidden-sm" data-id="f37cd627-62e7-490f-923f-5284589eb857">
                                <a href="/recipes/ultimate-bacon-lovers-sandwich/">
                                    View Full Recipe
                            </a>
                            </div>
                        </div>
                        <div class="recipe-item col-sm-6 col-md-4">
                            <a href="/recipes/bacon-brunch-burger/">
                                <img src={imageBaconBrunch} alt="bacon-brunch"></img>
                            </a>
                            <h3>Brunch Burger</h3>
                            <p>
                            Redefining brunch the only way we know how. Thick-cut.  <a class="hidden-xs full-recipe" href="/recipes/bacon-brunch-burger/">Full Recipe</a>
                            </p>
                            <div class="generic-btn arrow hidden-lg hidden-md hidden-sm" data-id="f37cd627-62e7-490f-923f-5284589eb857">
                                <a href="/recipes/bacon-brunch-burger/">
                                    View Full Recipe
                            </a>
                            </div>
                        </div>
                        <div class="recipe-item col-sm-6 col-md-4">
                            <a href="/recipes/steamed-bao-buns-bacon/">
                                <img src={imageBaconBao} alt="steamed-bao-buns-bacon"></img>
                            </a>
                            <h3>Bacon Bao</h3>
                            <p>
                            It’s bao meets our thick-cut bacon that's sure to wow everyone.  <a class="hidden-xs full-recipe" href="/recipes/steamed-bao-buns-bacon/">Full Recipe</a>
                            </p>
                            <div class="generic-btn arrow hidden-lg hidden-md hidden-sm" data-id="f37cd627-62e7-490f-923f-5284589eb857">
                                <a href="/recipes/steamed-bao-buns-bacon/">
                                    View Full Recipe
                            </a>
                            </div>
                        </div>
                        <div class="recipe-item col-sm-6 col-md-4">
                            <a href="/recipes/ultimate-blt/">
                                <img src={imageUltimateBLT} alt="The Wright Brand Ultimate BLT"></img>
                            </a>
                            <h3>Ultimate BLT</h3>
                            <p>
                            This fully loaded BLT is sure to blow your taste buds away.<a class="hidden-xs full-recipe" href="/recipes/ultimate-blt/">Full Recipe</a>
                            </p>
                            <div class="generic-btn arrow hidden-lg hidden-md hidden-sm" data-id="fae2f501-ed03-416a-b63f-d12e5af189a2">
                                <a href="/recipes/ultimate-blt/">
                                    View Full Recipe
                            </a>
                            </div>
                        </div>
                        <div class="recipe-item col-sm-6 col-md-4">
                            <a href="/recipes/bacon-wrapped-pickles/">
                                <img src={imageWrappedPickles} alt="ultimate-bacon-lovers-sandwich"></img>
                            </a>
                            <h3>The Bacon Wrapped Pickle</h3>
                            <p>
                            In a pickle for what appetizer to serve? Just wrap one in our bacon. <a class="hidden-xs full-recipe" href="/recipes/bacon-wrapped-pickles/">Full Recipe</a>
                            </p>
                            <div class="generic-btn arrow hidden-lg hidden-md hidden-sm" data-id="f37cd627-62e7-490f-923f-5284589eb857">
                                <a href="/recipes/bacon-wrapped-pickles/">
                                    View Full Recipe
                            </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="recipe-category-section">
                <div class="article-shadow hidden-xs"></div>
                <div class="container recipe-list-container">
                    <input type="hidden" id="hid-current-page" value="2" />
                    <input type="hidden" id="load-more-count" value="3" />
                    <div class="category-container">
                        <img class="category-image hidden-lg hidden-md hidden-sm" src={imageLollipopsSmall} alt="Appetizers" />
                        <div class="category-header">
                            <h2>Appetizers</h2>
                        </div>
                        <div class="generic-btn mobile-category-btn arrow hidden-lg hidden-md hidden-sm" data-id="f37cd627-62e7-490f-923f-5284589eb857">
                            <a class="more-recipes-btn">
                                View Recipes
                    </a>
                        </div>
                    </div>
                    <div class="recipe-list row">
                    <div class="recipe-item col-sm-6 col-md-4">
                            <a href="/recipes/bacon-shrimp-and-crawfish-skewers/">
                                <img src={imageShrimpCrawFish} alt="Bacon, Shrimp and Crawfish Skewers"></img>
                            </a>
                            <h3>Bacon, Shrimp and Crawfish Skewers</h3>
                            <p>
                                It’s surf and turf on our home turf: thick-cut bacon. The hearty...                            <a class="hidden-xs full-recipe" href="/recipes/bacon-shrimp-and-crawfish-skewers/">Full Recipe</a>
                            </p>
                            <div class="generic-btn arrow hidden-lg hidden-md hidden-sm" data-id="00000000-0000-0000-0000-000000000000">
                                <a href="/recipes/bacon-shrimp-and-crawfish-skewers/">
                                    View Full Recipe
                            </a>
                            </div>
                        </div>
                        <div class="recipe-item col-sm-6 col-md-4">
                            <a href="/recipes/bacon-wrapped-litl-smokies-sausages/">
                                <img src={imageLitlSmokies} alt="Bacon Wrapped Lit’l Smokies® Sausages"></img>
                            </a>
                            <h3>Bacon Wrapped Lit’l Smokies® Sausages</h3>
                            <p>
                                Featuring Wright&#174; Brand Bacon &amp; Hillshire Farm&#174; Lit&#39;l Smokies&#174;...                            <a class="hidden-xs full-recipe" href="/recipes/bacon-wrapped-litl-smokies-sausages/">Full Recipe</a>
                            </p>
                            <div class="generic-btn arrow hidden-lg hidden-md hidden-sm" data-id="f37cd627-62e7-490f-923f-5284589eb857">
                                <a href="/recipes/bacon-wrapped-litl-smokies-sausages/">
                                    View Full Recipe
                            </a>
                            </div>
                        </div>
                        <div class="recipe-item col-sm-6 col-md-4">
                            <a href="/recipes/bacon-backyard-corn/">
                                <img src={imageBaconWrapCorn} alt="Bacon Backyard Corn"></img>
                            </a>
                            <h3>Bacon Backyard Corn</h3>
                            <p>
                                We’ve always loved the taste of corn, but there’s a certain savory...                            <a class="hidden-xs full-recipe" href="/recipes/bacon-backyard-corn/">Full Recipe</a>
                            </p>
                            <div class="generic-btn arrow hidden-lg hidden-md hidden-sm" data-id="f37cd627-62e7-490f-923f-5284589eb857">
                                <a href="/recipes/bacon-backyard-corn/">
                                    View Full Recipe
                            </a>
                            </div>
                        </div>
                        <div class="recipe-item col-sm-6 col-md-4">
                            <a href="/recipes/steak-cut-heritage-cured-bacon-lollipops/">
                                <img src={imageLollipopsSmall} alt="Steak-Cut Heritage Cured Bacon Lollipops"></img>
                            </a>
                            <h3>Steak-Cut Heritage Cured Bacon Lollipops</h3>
                            <p>
                                Wright&#174; Brand Bacon skewered and grilled to crispy tender perfection. <a class="hidden-xs full-recipe" href="/recipes/steak-cut-heritage-cured-bacon-lollipops/">Full Recipe</a>
                            </p>
                            <div class="generic-btn arrow hidden-lg hidden-md hidden-sm" data-id="f37cd627-62e7-490f-923f-5284589eb857">
                                <a href="/recipes/steak-cut-heritage-cured-bacon-lollipops/">
                                    View Full Recipe
                            </a>
                            </div>
                        </div>
                        <div class="recipe-item col-sm-6 col-md-4">
                            <a href="/recipes/steamed-bao-buns-bacon/">
                                <img src={imageBaconBao} alt="steamed-bao-buns-bacon"></img>
                            </a>
                            <h3>Bacon Bao</h3>
                            <p>
                            It’s bao meets our thick-cut bacon that's sure to wow everyone.  <a class="hidden-xs full-recipe" href="/recipes/steamed-bao-buns-bacon/">Full Recipe</a>
                            </p>
                            <div class="generic-btn arrow hidden-lg hidden-md hidden-sm" data-id="f37cd627-62e7-490f-923f-5284589eb857">
                                <a href="/recipes/steamed-bao-buns-bacon/">
                                    View Full Recipe
                            </a>
                            </div>
                        </div>
                        <div class="recipe-item col-sm-6 col-md-4">
                            <a href="/recipes/bacon-wrapped-pickles/">
                                <img src={imageWrappedPickles} alt="bacon-wrapped-pickles"></img>
                            </a>
                            <h3>The Bacon Wrapped Pickle</h3>
                            <p>
                            In a pickle for what appetizer to serve? Just wrap one in our bacon. <a class="hidden-xs full-recipe" href="/recipes/bacon-wrapped-pickles/">Full Recipe</a>
                            </p>
                            <div class="generic-btn arrow hidden-lg hidden-md hidden-sm" data-id="f37cd627-62e7-490f-923f-5284589eb857">
                                <a href="/recipes/bacon-wrapped-pickles/">
                                    View Full Recipe
                            </a>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </section>
            <section class="recipe-category-section">
                <div class="article-shadow hidden-xs"></div>
                <div class="container recipe-list-container">
                    <input type="hidden" id="hid-current-page" value="2" />
                    <input type="hidden" id="load-more-count" value="3" />
                    <div class="category-container">
                        <img class="category-image hidden-lg hidden-md hidden-sm" src={imageBaconWaffles} alt="Breakfast" />
                        <div class="category-header">
                            <h2>Breakfast</h2>
                        </div>
                        <div class="generic-btn mobile-category-btn arrow hidden-lg hidden-md hidden-sm" data-id="43a04a6d-e0ad-45d3-b115-27123f23c5a3">
                            <a class="more-recipes-btn">
                                View Recipes
                    </a>
                        </div>
                    </div>
                    <div class="recipe-list row">
                        <div class="recipe-item col-sm-6 col-md-4">
                            <a href="/recipes/maple-flavored-bacon-waffles/">
                                <img src={imageBaconWaffles} alt="Maple-Flavored Bacon Waffles"></img>
                            </a>
                            <h3>Maple-Flavored Bacon Waffles</h3>
                            <p>
                                We’ve made it pretty obvious that we think our thick, hearty bacon can...                            <a class="hidden-xs full-recipe" href="/recipes/maple-flavored-bacon-waffles/">Full Recipe</a>
                            </p>
                            <div class="generic-btn arrow hidden-lg hidden-md hidden-sm" data-id="43a04a6d-e0ad-45d3-b115-27123f23c5a3">
                                <a href="/recipes/maple-flavored-bacon-waffles/">
                                    View Full Recipe
                            </a>
                            </div>
                        </div>
                        <div class="recipe-item col-sm-6 col-md-4">
                            <a href="/recipes/bacon-and-venison-scramble/">
                                <img src={'/tysonscore3.azureedge.net/assets/media/wrightbrand/images/recipes/venisonscramble_small.jpg'} alt="Bacon and Venison Scramble"></img>
                            </a>
                            <h3>Bacon and Venison Scramble</h3>
                            <p>
                                We know our thick-cut, smoky bacon is classic breakfast flavor, but...                            <a class="hidden-xs full-recipe" href="/recipes/bacon-and-venison-scramble/">Full Recipe</a>
                            </p>
                            <div class="generic-btn arrow hidden-lg hidden-md hidden-sm" data-id="43a04a6d-e0ad-45d3-b115-27123f23c5a3">
                                <a href="/recipes/bacon-and-venison-scramble/">
                                    View Full Recipe
                            </a>
                            </div>
                        </div>
                        <div class="recipe-item col-sm-6 col-md-4">
                            <a href="/recipes/loaded-cornbread/">
                                <img src={imageBaconCornBread} alt="Loaded Cornbread"></img>
                            </a>
                            <h3>Bacon Loaded Cornbread Recipe</h3>
                            <p>
                                Cornbread is pretty perfect. We thought we’d make it even more perfect...
                        <a class="hidden-xs full-recipe" href="/recipes/loaded-cornbread/">Full Recipe</a>
                            </p>
                            <div class="generic-btn arrow hidden-lg hidden-md hidden-sm" data-id="00000000-0000-0000-0000-000000000000">
                                <a href="/recipes/loaded-cornbread/">
                                    View Full Recipe
                            </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="recipe-category-section">
                <div class="article-shadow hidden-xs"></div>
                <div class="container recipe-list-container">
                    <input type="hidden" id="hid-current-page" value="2" />
                    <input type="hidden" id="load-more-count" value="9" />
                    <div class="category-container">
                        <img class="category-image hidden-lg hidden-md hidden-sm" src={imageBrazedChicken} alt="Lunch &amp; Dinner" />
                        <div class="category-header">
                            <h2>Lunch &amp; Dinner</h2>
                        </div>
                        <div class="generic-btn mobile-category-btn arrow hidden-lg hidden-md hidden-sm" data-id="fae2f501-ed03-416a-b63f-d12e5af189a2">
                            <a class="more-recipes-btn">
                                View Recipes
                    </a>
                        </div>
                    </div>
                    <div class="recipe-list row">
                        <div class="recipe-item col-sm-6 col-md-4">
                            <a href="/recipes/bacon-grilled-cheese-sandwiches-smothered-with-bacon-sloppy-joe/">
                                <img src={'/tysonscore3.azureedge.net/assets/media/wrightbrand/images/recipes/baconsloppyjoe_small.jpg'} alt="Bacon Grilled Cheese Sandwiches smothered with Bacon Sloppy Joe"></img>
                            </a>
                            <h3>Bacon Grilled Cheese Sandwiches smothered with Bacon Sloppy Joe</h3>
                            <p>
                                We take pride in our painstakingly prepared slices of thick-cut bacon....                            <a class="hidden-xs full-recipe" href="/recipes/bacon-grilled-cheese-sandwiches-smothered-with-bacon-sloppy-joe/">Full Recipe</a>
                            </p>
                            <div class="generic-btn arrow hidden-lg hidden-md hidden-sm" data-id="fae2f501-ed03-416a-b63f-d12e5af189a2">
                                <a href="/recipes/bacon-grilled-cheese-sandwiches-smothered-with-bacon-sloppy-joe/">
                                    View Full Recipe
                            </a>
                            </div>
                        </div>
                        <div class="recipe-item col-sm-6 col-md-4">
                            <a href="/recipes/bacon-wrapped-beer-can-duck-and-honey-beer-glaze/">
                                <img src={'/tysonscore3.azureedge.net/assets/media/wrightbrand/images/recipes/baconwrapduck_small.jpg'} alt="Bacon-Wrapped Beer Can Duck and Honey-Beer Glaze"></img>
                            </a>
                            <h3>Bacon Wrapped Duck Recipe with Beer Glaze</h3>
                            <p>
                                Are burgers and brats getting boring? Change up your next cookout with...                            <a class="hidden-xs full-recipe" href="/recipes/bacon-wrapped-beer-can-duck-and-honey-beer-glaze/">Full Recipe</a>
                            </p>
                            <div class="generic-btn arrow hidden-lg hidden-md hidden-sm" data-id="fae2f501-ed03-416a-b63f-d12e5af189a2">
                                <a href="/recipes/bacon-wrapped-beer-can-duck-and-honey-beer-glaze/">
                                    View Full Recipe
                            </a>
                            </div>
                        </div>
                        <div class="recipe-item col-sm-6 col-md-4">
                            <a href="/recipes/southern-fried-bacon-tacos/">
                                <img src={'/tysonscore3.azureedge.net/assets/media/wrightbrand/images/recipes/recipe3.jpg'} alt="Southern Fried Bacon Tacos"></img>
                            </a>
                            <h3>Southern Fried Bacon Tacos</h3>
                            <p>
                                A perfect blend of sweet, salty and bacon-y all wrapped up in one.                            <a class="hidden-xs full-recipe" href="/recipes/southern-fried-bacon-tacos/">Full Recipe</a>
                            </p>
                            <div class="generic-btn arrow hidden-lg hidden-md hidden-sm" data-id="fae2f501-ed03-416a-b63f-d12e5af189a2">
                                <a href="/recipes/southern-fried-bacon-tacos/">
                                    View Full Recipe
                            </a>
                            </div>
                        </div>
                        <div class="recipe-item col-sm-6 col-md-4">
                            <a href="/recipes/bacon-hazelnut-farro-salad-with-bourbon-bacon-vinaigrette/">
                                <img src={imageBaconHazelnut} alt="Bacon Hazelnut Farro Salad with Bourbon-Bacon Vinaigrette"></img>
                            </a>
                            <h3>Bacon Hazelnut Farro Salad with Bourbon-Bacon Vinaigrette</h3>
                            <p>
                                Side salad? Nah, you’ll never want to put this one to the side.                            <a class="hidden-xs full-recipe" href="/recipes/bacon-hazelnut-farro-salad-with-bourbon-bacon-vinaigrette/">Full Recipe</a>
                            </p>
                            <div class="generic-btn arrow hidden-lg hidden-md hidden-sm" data-id="fae2f501-ed03-416a-b63f-d12e5af189a2">
                                <a href="/recipes/bacon-hazelnut-farro-salad-with-bourbon-bacon-vinaigrette/">
                                    View Full Recipe
                            </a>
                            </div>
                        </div>
                        <div class="recipe-item col-sm-6 col-md-4" >
                            <a href="/recipes/bacon-and-ale-skillet-braised-chicken-thighs/">
                                <img src={imageBrazedChicken} alt="Bacon and Ale Skillet Braised Chicken Thighs"></img>
                            </a>
                            <h3>Bacon & Ale Skillet Braised Chicken Thighs</h3>
                            <p>Everything is better with beer.<a class="hidden-xs full-recipe" href="/recipes/bacon-and-ale-skillet-braised-chicken-thighs/">Full Recipe</a></p>
                            <div class="generic-btn arrow hidden-lg hidden-md hidden-sm" data-id="00000000-0000-0000-0000-000000000000">
                                <a href="/recipes/bacon-and-ale-skillet-braised-chicken-thighs/">
                                    View Full Recipe
                        </a>
                            </div>
                        </div><div class="recipe-item col-sm-6 col-md-4" >
                            <a href="/recipes/bacon-backyard-corn/">
                                <img src={imageBaconWrapCorn} alt="Bacon Backyard Corn"></img>
                            </a>
                            <h3>Bacon Backyard Corn</h3>
                            <p>We’ve always loved the taste of corn, but there’s a certain savory...<a class="hidden-xs full-recipe" href="/recipes/bacon-backyard-corn/">Full Recipe</a></p>
                            <div class="generic-btn arrow hidden-lg hidden-md hidden-sm" data-id="00000000-0000-0000-0000-000000000000">
                                <a href="/recipes/bacon-backyard-corn/">
                                    View Full Recipe
                        </a>
                            </div>
                        </div><div class="recipe-item col-sm-6 col-md-4" >
                            <a href="/recipes/the-tex-bacon-and-rice-bowl/">
                                <img src={"/tysonscore3.azureedge.net/assets/media/wrightbrand/images/recipes/texasbaconricebowl_small.png"} alt="The TEX Bacon and Rice Bowl"></img>
                            </a>
                            <h3>The Tex Bacon Rice Bowl Recipe</h3>
                            <p>Bold Texas flavors complement hickory smoked bacon with melted tomato,...<a class="hidden-xs full-recipe" href="/recipes/the-tex-bacon-and-rice-bowl/">Full Recipe</a></p>
                            <div class="generic-btn arrow hidden-lg hidden-md hidden-sm" data-id="00000000-0000-0000-0000-000000000000">
                                <a href="/recipes/the-tex-bacon-and-rice-bowl/">
                                    View Full Recipe
                        </a>
                            </div>
                        </div>
                        <div class="recipe-item col-sm-6 col-md-4">
                            <a href="/recipes/ultimate-blt">
                                <img src={imageUltimateBLT} alt="The Wright Brand Ultimate BLT"></img>
                            </a>
                            <h3>Ultimate BLT</h3>
                            <p>
                            This fully loaded BLT is sure to blow your taste buds away.<a class="hidden-xs full-recipe" href="/recipes/ultimate-blt">Full Recipe</a>
                            </p>
                            <div class="generic-btn arrow hidden-lg hidden-md hidden-sm" data-id="fae2f501-ed03-416a-b63f-d12e5af189a2">
                                <a href="/recipes/ultimate-blt">
                                    View Full Recipe
                            </a>
                            </div>
                        </div>
                        <div class="recipe-item col-sm-6 col-md-4">
                            <a href="/recipes/bacon-brunch-burger/">
                                <img src={imageBaconBrunch} alt="bacon-brunch"></img>
                            </a>
                            <h3>Brunch Burger</h3>
                            <p>
                            Redefining brunch the only way we know how. Thick-cut.  <a class="hidden-xs full-recipe" href="/recipes/bacon-brunch-burger/">Full Recipe</a>
                            </p>
                            <div class="generic-btn arrow hidden-lg hidden-md hidden-sm" data-id="f37cd627-62e7-490f-923f-5284589eb857">
                                <a href="/recipes/bacon-brunch-burger/">
                                    View Full Recipe
                            </a>
                            </div>
                        </div>
                        <div class="recipe-item col-sm-6 col-md-4">
                            <a href="/recipes/ultimate-bacon-lovers-sandwich/">
                                <img src={imageBaconLoversSan} alt="ultimate-bacon-lovers-sandwich"></img>
                            </a>
                            <h3>“The Adulting” Ultimate Bacon Lover's Sandwich</h3>
                            <p>
                            It’s called 'Adulting' because this sandwich isn't for beginners. <a class="hidden-xs full-recipe" href="/recipes/ultimate-bacon-lovers-sandwich/">Full Recipe</a>
                            </p>
                            <div class="generic-btn arrow hidden-lg hidden-md hidden-sm" data-id="f37cd627-62e7-490f-923f-5284589eb857">
                                <a href="/recipes/ultimate-bacon-lovers-sandwich/">
                                    View Full Recipe
                            </a>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
            <section class="recipe-category-section">
                <div class="article-shadow hidden-xs"></div>
                <div class="container recipe-list-container">
                    <input type="hidden" id="hid-current-page" value="2" />
                    <input type="hidden" id="load-more-count" value="9" />
                    <div class="category-container">
                        <img class="category-image hidden-lg hidden-md hidden-sm" src={imageKlondikeSandwich} alt="Dessert" />
                        <div class="category-header">
                            <h2>Dessert</h2>
                        </div>
                        <div class="generic-btn mobile-category-btn arrow hidden-lg hidden-md hidden-sm" data-id="fae2f501-ed03-416a-b63f-d12e5af189a2">
                            <a class="more-recipes-btn">
                                View Recipes
                    </a>
                        </div>
                    </div>
                    <div class="recipe-list row">
                        <div class="recipe-item col-sm-6 col-md-4">
                            <a href="/recipes/brand-klondike-bar-sandwich/">
                                <img src={imageKlondikeSandwich} alt="The Wright Brand Klondike Sandwich"></img>
                            </a>
                            <h3>The Wright® Brand Klondike® Sandwich</h3>
                            <p>
                                A savory twist to a childhood favorite!                            <a class="hidden-xs full-recipe" href="/recipes/brand-klondike-bar-sandwich/">Full Recipe</a>
                            </p>
                            <div class="generic-btn arrow hidden-lg hidden-md hidden-sm" data-id="fae2f501-ed03-416a-b63f-d12e5af189a2">
                                <a href="/recipes/brand-klondike-bar-sandwich/">
                                    View Full Recipe
                            </a>
                            </div>
                        </div>
                        <div class="recipe-item col-sm-6 col-md-4">
                            <a href="/recipes/brand-klondike-bar-bonbons/">
                                <img src={imageKlondikeBonbons} alt="The Wright Brand Klondike BonBons"></img>
                            </a>
                            <h3>The Wright® Brand Klondike® BonBons</h3>
                            <p>
                                Bite-sized treats that cure your salty and sweet cravings.                            <a class="hidden-xs full-recipe" href="/recipes/brand-klondike-bar-bonbons/">Full Recipe</a>
                            </p>
                            <div class="generic-btn arrow hidden-lg hidden-md hidden-sm" data-id="fae2f501-ed03-416a-b63f-d12e5af189a2">
                                <a href="/recipes/brand-klondike-bar-bonbons/">
                                    View Full Recipe
                            </a>
                            </div>
                        </div>
                        <div class="recipe-item col-sm-6 col-md-4">
                            <a href="/recipes/brand-klondike-bar-brittle/">
                                <img src={imageKlondikeBrittle} alt="The Wright Brand Klondike Brittle"></img>
                            </a>
                            <h3>The Wright® Brand Klondike® Brittle</h3>
                            <p>
                                Use Wright® Brand Bacon to spice up every dish, even ice cream!                            <a class="hidden-xs full-recipe" href="/recipes/brand-klondike-bar-brittle/">Full Recipe</a>
                            </p>
                            <div class="generic-btn arrow hidden-lg hidden-md hidden-sm" data-id="fae2f501-ed03-416a-b63f-d12e5af189a2">
                                <a href="/recipes/brand-klondike-bar-brittle/">
                                    View Full Recipe
                            </a>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </article>

        <article class="locator-callout">
            <div class="quick-form-container">


                <div class="product-locator-search">
                    <h2>Find Our Products</h2>
                    <form data-url="/locator/" action="/locator/">
                        <input Value="ANY_ANY" id="PROD" name="PROD" type="hidden" value="ANY_ANY" />
                        <div class="input-wrapper">
                            <input data-val="true" data-val-regex="Please enter a 5-digit ZIP code" data-val-regex-pattern="^\d{5}" data-val-required="Please enter a 5-digit ZIP code" id="ZIP" maxLength="5" name="ZIP" placeholder="Enter Zip" type="tel" defaultValue="" />
                            <input type="submit" value="Locate Bacon"></input>
                            <span class="field-validation-valid" data-valmsg-for="ZIP" data-valmsg-replace="true"></span>
                        </div>
                    </form>
                </div>

            </div>
        </article>
    </Layout>
)

export default SecondPage
